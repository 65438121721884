@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Source+Sans+3:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans 3', sans-serif;
}

body{
  overflow: hidden;
}

.link {
  font-size: 14px !important;
  color: #A1BEFF !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  text-decoration: underline !important;
}

.svg-icons {
  height: 1.5em !important;
  width: 1.5em !important;
}

#menu .ant-menu-item-selected {
  background-color: #ff0000 !important;
}
.map {
  width: 70%;
  stroke: #FFFF;
  stroke-width: 0.2;
}
.svg-map__location {
  fill: #808080;
  cursor: pointer;
  transition: 0.1s;
}
.svg-map__location:hover {
  fill: #ababab;
}
.svg-map__location:focus {
  fill: #ff5e00;
  outline: 0;
}

.leaflet-div-icon{
  border: none;
  background-color: #00000000;
}
